.ressources-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 700px;

    a {
        text-decoration: none;
        color: $black;
        width: 100%;

        .ressource-card {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 20px;
            border-radius: 0.5rem;
            margin-bottom: 20px;
            border: 1px solid #C2C2C2;
            background-color: $white;
            transition: 0.5s;

            &:hover {
                background-color: $light-grey;
            }

            h3 {
                font-weight: 500;
            }
        }
    }

    .ressource-description {
        color: rgb(145, 145, 145);
    }
}

@media(max-width: 800px) {
    .ressources-cards-container {
        width: 100%;
    }
}