.section-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 50px;
    gap: 1rem;

    .content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    h2 {
        font-size: 20px;
        font-weight: 500;
    }

}