.splash-page-navigation-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;

    .title-container {
        padding-inline: 20px;
        min-height: 10vh;
        background: $grey;
        color: $dark-blue;

        h1 {
            font-weight: 600;
        }
    }

    .fscd-conference-banner {
        min-height: 20vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #363C4D;
        width: 100%;

        .fscd-background {
            width: 60%;
            height: 100%;
            background: url("../../assets/img/wordcloud-7.png");
            background-position: center;
            background-size: cover;
            margin: 0;
        }
    }

    .main {
        padding-block: 2rem;
        min-height: 60vh;
        gap: 2rem;
        position: unset;
        height: auto;

    }

    .first-container {
        display: flex;
        width: 100%;
        gap: 2rem;
    }

    .second-container {
        // background: $white2;
        padding: 20px;
        border-radius: 10px;
        width: 100%;
        gap: 0.5rem;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        justify-items: center;
        align-items: center;

        a {
            text-align: center;
            width: 100%;
            border: 2px solid $grey;
            border-radius: 10px;
            background: $white;
            padding: 10px;
            padding-left: 10px;
            font-size: 16px;
            font-weight: 500;
            transition: 0.25s;
            font-size: 15px;

            &:hover {
                color: $blue;
                background: $white2;
            }
        }
    }
}

@media(max-width: 1200px) {
    .splash-page-navigation-container {
        .fscd-conference-banner {
            .fscd-background {
                width: 80%;
            }
        }

        .first-container {
            flex-direction: column;
        }

        a {
            font-size: 25px;
        }
    }
}

@media(max-width: 700px) {
    .splash-page-navigation-container {
        .second-container {
            grid-template-columns: repeat(1, minmax(0, 1fr));

            a {
                font-size: 17px;
                text-align: center;
                padding: 10px;
            }
        }
    }
}