.industrial-application-card {
    text-decoration: none;
    color: $black;
    display: flex;
    justify-content: left;
    gap: 3rem;
    border-radius: 10px;
    border: 2px solid #C2C2C2;
    background: $white;
    padding: 10px;
    height: auto;
    font-size: 14px;
    transition: 0.5s;
    width: 700px;

    .name {
        width: 50%;
        color: #3E98DB;
        display: flex;
    }

    .tags-container {
        width: 50%;
        align-items: flex-end;
        justify-content: right;
        flex-direction: row;
        display: flex;
        gap: 0.5rem;
    }

    &:hover {
        background: $light-grey2;

        .name {

            text-decoration: underline;
        }
    }
}

@media(max-width: 800px) {
    .industrial-application-card {
        justify-content: space-between;
        width: 100%;
        height: 100%;
        flex-direction: column;
        gap: 1rem;

        .name,
        .tags-container {
            width: 100%;
        }

        .tags-container {
            align-items: start;
            flex-direction: column;
        }
    }
}