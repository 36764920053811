.navbar-container {
    z-index: 500;
    position: fixed;
    display: flex;
    height: auto;
    min-height: 50px;
    background: $white;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #eaeaea;
    width: 100vw;

    .navbar {
        display: flex;
        width: 60vw;
    }

    .nav-items {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        height: 40px;

        li {
            display: flex;
            height: 40px;
            flex-direction: row;
            align-items: center;

            .nav-link {
                color: $black;
                text-decoration: none;
                padding: 5px;

                transition: 0.25s;
                border-radius: 4px;



                &.selected {
                    color: $white;

                    &:hover {
                        color: $white;
                    }
                }

                &.rewriting {
                    &.selected {
                        background: $turquoise;

                        &:hover {
                            background: $turquoise;
                        }
                    }
                }

                &.lambda-calculus {
                    &.selected {
                        background: $blue;

                        &:hover {
                            background: $blue;
                        }
                    }
                }

                &.logic {
                    &.selected {
                        background: $dark-blue;

                        &:hover {
                            background: $dark-blue;
                        }
                    }
                }

                &.intranet {
                    &.selected {
                        background: $black;

                        &:hover {
                            background: $black;
                        }
                    }
                }

                &:hover {
                    background: $light-grey2;
                    color: $black-hover;
                }
            }
        }

        .logout-container {

            button {
                background: $white;
                border: 2px solid $black;
                padding: 2.5px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: 0.25s;

                &:hover {
                    background: $black;
                    color: $white;
                }
            }
        }
    }


}

.bars {
    display: none;
}

.bars ul.nav-link.active {
    background: green($color: #000000);
}

@media(max-width: 1400px) {
    .navbar-container {
        .navbar {
            width: 75vw;
        }
    }
}

@media(max-width: 850px) {
    .navbar-container {
        .navbar {
            width: 85vw;
        }
    }
}


@media(max-width: 600px) {
    @keyframes goDown {
        from {
            top: -100px;
        }

        to {
            top: 0;
        }
    }

    .navbar-container {
        padding: 0.5rem;
        position: fixed;
        justify-content: flex-start;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

        .navbar {
            flex-direction: column;
            width: 100%;
            gap: 1rem;
        }

        .nav-items {
            display: none;
            height: 100vh;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
            transition: opacity 0.5s ease-in-out;

            &.active {
                animation: goDown 1s;
                height: 100%;
                display: flex;
                opacity: 1;

            }

            .nav-link {
                border-radius: 10px;
                text-align: center;
                padding: 10px;
                width: 100%;
            }

        }

        .bars-container {
            display: flex;
            justify-content: flex-end;

            .bars {
                color: #2C3E50;
                box-sizing: content-box;
                display: flex;
                padding: 12.5px;
                border-radius: 4px;
                gap: 6px;
                height: auto;
                cursor: pointer;
                background-color: $light-grey2;
            }
        }
    }
}