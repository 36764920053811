.title-container {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    h1 {
        font-weight: 500;
    }
}