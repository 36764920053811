.login-page {
    background: linear-gradient($dark-blue-hover2, $dark-blue);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    padding-top: 10%;

    .alert {
        position: absolute;
        top: 10px;
        transition-duration: 1s;
    }

    .login-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $white;

        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        padding: 40px;
        border-radius: 5px;
        width: 500px;

        button {
            background: $blue;
            width: 150px;
        }

        input {
            background: $white;
        }
    }

    .footer {
        position: relative;
        bottom: 0;
    }
}

@media(max-width: 850px) {
    .login-page {
        .login-card {
            width: 95vw;
        }
    }
}