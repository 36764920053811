.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $dark-blue;
    width: 100%;
    min-height: 10vh;
    padding-block: 20px;
    color: $white;
    text-align: center;
    margin-top: 50px;


    p {
        font-size: 12px;

        a {
            color: $white2;
            text-decoration: underline;
            transition-duration: 0.25s;
        }
    }


}