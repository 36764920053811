.archive-page {
    .archive-content {
      padding: 20px;
    }
  
    .archive-title {
      font-size: 2em;
      margin-bottom: 20px;
    }
  
    .loading-text,
    .no-data-text {
      font-size: 1.2em;
      color: #666;
    }
  
    .archive-list {
      list-style: none;
      padding: 0;
    }
  
    .archive-item {
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 20px;
      background-color: #f9f9f9;
  
      .archive-item-title {
        font-size: 1.5em;
        margin-bottom: 10px;
      }
  
      .archive-item-content {
        margin-bottom: 10px;
      }
  
      .archive-item-date {
        font-size: 0.9em;
        color: #999;
      }
  
      button {
        margin-right: 10px;
        padding: 10px 15px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        color: #fff;
  
        &.restore-button {
          background-color: #4CAF50; // Vert pour Restaurer
        }
  
        &.delete-button {
          background-color: #f44336; // Rouge pour Supprimer
        }
  
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .archive-item {
    /* Autres styles pour archive-item */
    display: flex;
    flex-direction: column;
    align-items: center; /* Centre les éléments enfants horizontalement */
  }
  
  .archive-item-actions {
    margin-top: 10px;
    display: flex;
    justify-content: center; /* Centre les boutons horizontalement */
    gap: 10px; /* Espace entre les boutons */
  }
  
  .restore-button,
  .delete-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .restore-button {
    background-color: #4CAF50; /* Couleur de fond pour le bouton Restaurer */
    color: white;
  }
  
  .delete-button {
    background-color: #f44336; /* Couleur de fond pour le bouton Supprimer */
    color: white;
  }
  
  .confirm-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .confirm-dialog-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .confirm-button,
  .cancel-button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirm-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
  }
  .search-container {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .search-input {
    padding: 10px;
    width: 100%;
    max-width: 500px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .category-menu {
    margin-bottom: 20px;
  }
  
  .category-button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    margin-right: 10px;
    padding: 10px 15px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .category-button.active {
    background-color: #007bff;
    color: #fff;
  }
  