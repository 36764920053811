.container {
    .main {
        .industrial-applications-cards-container {
            justify-content: center;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            gap: 1rem;
            justify-items: center;
            /* Centrer horizontalement */
        }

        .industrial-applications-cards-container:last-child {
            margin-bottom: 1rem;
        }
    }
}

@media(max-width: 800px) {
    .container {
        .main {
            .industrial-applications-cards-container {
                grid-template-columns: repeat(2, minmax(0, 1fr));
                flex-wrap: wrap;
                justify-content: center;
                flex-direction: row;
            }
        }
    }
}

@media(max-width: 500px) {
    .container {
        .main {
            .industrial-applications-cards-container {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }
    }
}