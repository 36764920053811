.intranet-card {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $black;
    width: 300px;
    height: 200px;
    border-radius: 1rem;
    background: $white;
    outline: 2px solid $grey;
    transition: 0.1s;
    cursor: pointer;

    &:hover {
        outline-width: 4px;
        outline-color: $grey;
    }
}