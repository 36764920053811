.upload-page {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .alert {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        position: absolute;
        width: 100vw;
        height: 50px;
        top: 10px;
    }

    .upload-form {
        min-width: 700px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border: 2px solid $grey;
        align-items: center;
        padding: 1rem;
        border-radius: 1rem;

        button {
            cursor: pointer;
        }
    }

    .uploaded-files-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        a.downloadable-file {
            display: flex;
            background: $light-grey;
            padding: 0.5rem;
            padding-block: 1rem;
            border-radius: 0.3rem;
            justify-content: space-between;
            align-items: center;
            gap: 0.5rem;
            transition: 0.25s;
            text-decoration: underline;

            &:hover {
                scale: 1.025;
                background: $light-grey2;

                svg {
                    transition: 0.25s;
                    color: #1873b8;
                }
            }
        }
    }
}