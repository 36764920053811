@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;1,500&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: "Poppins-Medium";
  src: url(../assets/fonts/poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: "Inter-Regular";
  src: url(../assets/fonts/inter/Inter-Regular.ttf);
}



$font1: 'Poppins',
sans-serif;
$blue : #3669A2;
$blue2 : #1d4778;
$dark-blue: #2C3E50;
$dark-blue-hover1: #34485c;
$dark-blue-hover2: #243546;
$turquoise: #18BC9C;
$turquoise-hover1: #25cdab;
$turquoise-hover2: #1f9f85;
$black: #303030;
$black-hover: #444444;
$white: rgb(250, 250, 250);
$white2: #f3f3f3;
$light-grey: #EEEEEE;
$light-grey2: #e6e6e6;
$grey: #E9E9E9;
$dark-grey: #7a7a7a;

@mixin verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 500;
}



body {
  width: 100%;
  min-height: 100vh;
  background: $white;
  font-family: $font1;
}

#root {
  display: flex;
  width: 100%;
  height: 100%;
}

.main {
  
  margin: 0 20%;
  width: 60%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50px;
  min-height: 90vh;
  padding-block: 20px;

  &.no-nav-bar {
    width: 60%;
    top: 0px;
  }
}



li {
  list-style-type: none;
}

.container {
  background: #FAFAFA;
  min-height: 100vh;
  min-height: 100%;
  width: 100%;
}


p {
  font-size: 13px;
}

// .container {
//   &.intranet {
//     background: $dark-blue;

//     .main {
//       background: $white;
//     }
//   }
// }




// .login-page,
// .intranet-write-post-page,
// .rewriting-ressources-page,
// .lambda-calculus-main-page,
// .logic-main-page,
// .rewriting-learning-ressources-page {
//   width: 100%;
//   min-height: 100vh;
//   min-height: 100%;

//   .footer {
//     position: fixed;
//     bottom: 0;
//   }
// }


@media(max-width: 1700px) {
  .main {
    margin: 0 12.5%;
    width: 75%;

    &.no-nav-bar {
      width: 75%;
    }
  }
}

@media(max-width: 1350px) {
  .main {
    margin: 0 2.5%;
    width: 95%;

    &.no-nav-bar {
      width: 95%;
    }
  }
}

// @media(max-width: 600px) {
//   .main {
//     margin: 0 5%;
//     width: 90%;

//     &.no-nav-bar {
//       width: 90%;
//     }
//   }
// }