a.button-page {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 1.5rem 0;
    display: flex;
    border-radius: 20px;
    font-size: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    align-items: center;
    justify-content: center;
    min-height: 70px;
    width: 100%;
    text-decoration: none;
    transition-duration: 0.25s;

    // &:hover {
    //     scale: 1.05;
    // }

    .arrow {
        display: flex;

    }


    &.intranet {
        width: 150px;
        padding: 0.5rem 0;
        font-size: 15px;
        min-height: 50px;
    }
}