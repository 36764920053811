.path-container {
    padding-block: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;

    li {
        display: flex;
        align-items: center;

        a {
            font-weight: 500;
            color: $dark-grey;
            text-decoration: none;
            padding: 0.25rem 0.5rem;
            border-radius: 4px;
            font-size: 15px;
            transition: 0.25s;

            &:hover {
                background: $light-grey2;
            }
        }

        &::before {
            content: "/";
            color: $dark-grey;
            margin: 0 0.5rem;
        }

        &:first-child::before {
            content: none;
        }

        &:last-child {
            a {
                color: $black;
            }
        }
    }

    &.rewriting {
        li {
            &:last-child {
                a {
                    color: $turquoise;
                }
            }
        }
    }

    &.logic {
        li {
            &:last-child {
                a {
                    color: $dark-blue;
                }
            }
        }
    }

    &.lambda-calculus {
        li {
            &:last-child {
                a {
                    color: $blue;
                }
            }
        }
    }
}

@media(max-width: 850px) {
    .path-container {
        flex-direction: column;
    }
}